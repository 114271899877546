import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { DonationService } from 'services/DonationService';
import actions from './actions';

function* paymentSepa(args) {
  yield put(actions.setLoading({ status: true }));

  const { payload } = args;
  const response = yield call(DonationService.paymentCreation, payload);

  yield put(actions.setPaymentResponse(response));

  if (response?.status === 201) {
    yield put(push(`/project/${payload.project_alias}/donation/step3`));
  } else {
    yield put(actions.setPaymentAlert({ isVisible: true }));
    window.scrollTo(0, 0);
  }

  yield put(actions.setLoading({ status: false }));
}

function* paymentWallis(args) {
  yield put(actions.setLoading({ status: true }));

  const { payload } = args;
  const response = yield call(DonationService.paymentCreation, payload);

  if (response?.status === 201) {
    window.location.href = response.data.redirectUrl
  } else {
    yield put(actions.setPaymentAlert({ isVisible: true }));
    window.scrollTo(0, 0);
  }

  yield put(actions.setLoading({ status: false }));
}

function* paymentWallisConfirmation(args) {
  yield put(actions.setLoading({ status: true }));

  const { payload: { donationId, payload, alias } } = args;
  const feedbackUrl = `/project/${alias}/payment-feedback/${donationId}`;
  const cancelUrl = `/project/${alias}/donation/cancel`;
  const response = yield call(DonationService.paymentWallisConfirmation, { payload, donationId });

  if (response?.status === 200) {
    window.location.href = payload.state ? feedbackUrl : cancelUrl;
  } else {
    yield put(actions.setPaymentAlert({ isVisible: true }));
    window.scrollTo(0, 0);
  }

  yield put(actions.setLoading({ status: false }));
}

function* paymentComment(args) {
  yield put(actions.setLoading({ status: true }));

  const { payload: { formData, alias } } = args;
  const response = yield call(DonationService.paymentComment, formData);

  yield put(actions.setPaymentResponse(response));

  if (response?.status === 201) {
    yield put(push(`/project/${alias}?tab=supporters`));
  }

  yield put(actions.setLoading({ status: false }));
}

export default function* donationSaga() {
  yield all([
    takeLatest(actions.PAYMENT_SEPA, paymentSepa),
    takeLatest(actions.PAYMENT_WALLIS, paymentWallis),
    takeLatest(actions.PAYMENT_WALLIS_CONFIRMATION, paymentWallisConfirmation),
    takeLatest(actions.PAYMENT_COMMENT, paymentComment),
  ]);
}
