import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import day from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoInformationCircle } from 'react-icons/io5';

import EURO_ICON_SRC from './icons/euro.svg';
import PERSON_ICON_SRC from './icons/person.svg';
import CLOCK_ICON_SRC from './icons/clock.svg';

import { selectProject, selectLoading } from '../../../redux/project/selectors';
import { projectActions } from '../../../redux/project/actions';

import * as S from './styles';
import ProgressBar from '../../../components/progress-bar';
import Loader from '../../../components/loaders/local-loader'

import { VALID_STATUSES, RECEIVED_CASH_STATUSES, PENDING_STATUS } from '../../../constants/payment-statuses'
import { convertToEuro } from '../../../utils/helpers';

interface ProjectParams {
  alias: string;
}

day.extend(relativeTime);

const Stats = () => {
  const dispatch = useDispatch();
  const project = useSelector(selectProject);
  const loading = useSelector(selectLoading);
  const { alias } = useParams<ProjectParams>();
  const { fundSum, finishedAt, isFinished, projectDonations, isSuccessful, isImplemented } = project;

  useMount(() => {
    dispatch(projectActions.loadProject(alias));
  });

  const donationCount = projectDonations.filter(d => VALID_STATUSES.includes(d.status) && d.isShowSepaPeriod).length;
  const receivedDonations = projectDonations.filter(d => RECEIVED_CASH_STATUSES.includes(d.status));
  const pendingDonations = projectDonations.filter(d => PENDING_STATUS.includes(d.status) && d.isShowSepaPeriod);
  const receivedSum = receivedDonations.reduce((accumulator, current) => accumulator + parseInt(current.donationSum), 0);
  const pendingSum = pendingDonations.reduce((accumulator, current) => accumulator + parseInt(current.donationSum), 0);
  const targetSum = parseInt(fundSum, 10);
  const missedSum = (targetSum - receivedSum) > 0 ? targetSum - receivedSum : 0;
  const progressMain = Math.ceil(receivedSum / targetSum * 100) || 0;
  const progressRecent = Math.ceil(pendingSum / targetSum * 100) || 0;
  const daysToEnd = day(finishedAt).diff(day(), 'day');
  const totallMinutesToEnd = day(finishedAt).diff(day(), 'minute');
  const hoursToEnd = Math.floor((totallMinutesToEnd - (daysToEnd * 24 * 60)) / 60);
  const minutesToEnd = Math.floor((totallMinutesToEnd - (daysToEnd * 24 * 60) - hoursToEnd * 60));
  const isCompleted = isFinished || isImplemented || totallMinutesToEnd < 0;

  const renderTime = () => (
    <>
      <div>noch <span className='font-weight-bold'>{daysToEnd} <span>Tage</span></span></div>
      <div>{hoursToEnd} Std., {minutesToEnd} Min.</div>
    </>
  )

  const renderTooltip = () => (
    <Tooltip id='tooltip' className='mytooltip'>
      <small>
        <b>Der helle Balken und die Zahlen in Klammern zeigen Spendenzusagen.</b>
        <br />
        <b>Diese Spenden sind demnach noch nicht auf dem Konto eingegangen.</b>
        <br />
        <b>Sobald sie eingegangen sind, fallen die Klammern weg und sie addieren sich zum Rest.</b>
        <br />
        <b>Sollten die Spenden nicht binnen 7 Tagen eingehen, verfallen die Zusagen.</b>
      </small>
    </Tooltip>
  )

  const renderCompleted = () => {
    if (isImplemented) return <div>Umgesetzt</div>;
    if (isFinished && (isSuccessful === 1)) return <div>Erfolgreich</div>;

    return <div>Beendet</div>
  };

  const renderContent = () => (
    <S.StyledRow>
      <Col sm={6} xs={12}>
        <Row>
          <S.ProgressBarCol>
            <ProgressBar main={progressMain} recent={progressRecent} />
          </S.ProgressBarCol>
        </Row>
        <Row>
          <Col sm={6} xs={12}>
            <S.ProgressDigitsHeader>
              { progressMain + '% ' }
              { progressRecent > 0 && <S.ProgressExtraTextHeader>
                { '(+' + progressRecent + '%)' }
                <OverlayTrigger overlay={renderTooltip()}>
                  <IoInformationCircle color='#D4004B' size='18px' />
                </OverlayTrigger>
              </S.ProgressExtraTextHeader> }
            </S.ProgressDigitsHeader>
          </Col>
          <S.StatCol sm={6} xs={12}>
            <S.ProgressBoldText>
              { convertToEuro(receivedSum) + ' ' }
              { pendingSum > 0 && <S.ProgressExtraText>(+{convertToEuro(pendingSum)}) finanziert</S.ProgressExtraText> }
            </S.ProgressBoldText>
            <S.ProgressBoldText>
              {convertToEuro(missedSum)} <S.ProgressExtraText>fehlen noch</S.ProgressExtraText>
            </S.ProgressBoldText>
          </S.StatCol>
        </Row>
      </Col>
      <Col sm={6} xs={12}>
        <S.StatRow>
          <S.ItemColStyled sm={3} xs={12}>
            <img src={EURO_ICON_SRC} alt='euro icon'/>
            <>
              <div className='font-weight-bold'>{convertToEuro(targetSum)}</div>
              <div>benötigt</div>
            </>
          </S.ItemColStyled>
          <S.ItemColStyled sm={4} xs={12}>
            <img src={PERSON_ICON_SRC} alt='person icon'/>
            <>
              <div className='font-weight-bold'>{donationCount}</div>
              <div>Unterstützer</div>
            </>
          </S.ItemColStyled>
          <S.ItemColStyled sm={5} xs={12}>
            <img src={CLOCK_ICON_SRC} alt='clock icon'/>
            <>
              { isCompleted ? renderCompleted() : renderTime() }
            </>
          </S.ItemColStyled>
        </S.StatRow>
      </Col>
    </S.StyledRow>
  )

  return (
    <S.StyledContainer fluid>
      <Container fluid='lg'>
        { loading.project ? <Loader /> : renderContent() }
      </Container>
    </S.StyledContainer>
  );
};

export default Stats;
