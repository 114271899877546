import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { Container, Row, Col } from 'react-bootstrap';

import { selectProjects, selectFinishedProjects, selectLoading } from '../../../redux/project/selectors';
import { projectActions } from '../../../redux/project/actions';

import ProjectCard from '../project-card';
import Loader from '../../../components/loaders/local-loader'

import * as S from './styles';

interface IProps {
  finished?: boolean
};

const Projects = ({ finished = false }: IProps) => {
  const dispatch = useDispatch();
  const { _embedded: { projects }, page, page_count } = useSelector(finished ? selectFinishedProjects : selectProjects);
  const loading = useSelector(selectLoading);

  useMount(() => {
    if (page) return;

    finished ? dispatch(projectActions.loadFinishedProjects({})) : dispatch(projectActions.loadProjects());
  });

  const loadMoreProjects = () => {
    if (loading.projects) return;

    if (finished) {
      dispatch(projectActions.loadFinishedProjects({ page: page + 1 }));
    } else {
      dispatch(projectActions.loadProjects({ page: page + 1 }));
    }
  };

  const renderCards = () => projects.map(p => <ProjectCard {...p} key={p.projectId} />);

  return (
    <S.Wrapper>
      <Container fluid='lg'>
        <Row>
          <Col>
            <h2 id='projects'>{ finished ? 'Beendete Projekte' : 'Aktuelle Projekte' }</h2>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col>
            <S.ProjectsList>
              { (loading.projects && !projects.length) ? <Loader /> : renderCards() }
            </S.ProjectsList>
            { page !== page_count && <S.MoreProjects onClick={loadMoreProjects}>Mehr Projekte zeigen</S.MoreProjects> }
          </Col>
        </Row>
      </Container>
    </S.Wrapper>
  );
};

export default Projects;
