import { API_ROUTES } from 'constants/routes';
import { BaseService } from './BaseService';
import StorageUtils from 'utils/StorageUtils';

class DonationService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  paymentCreation = async (credentials) => {
    const { userAgent } = this;

    userAgent.interceptors.request.use((config) => {
      const token = StorageUtils.restoreToken();

      config.headers.authorization = `Bearer ${token}`;

      return config;
    });

    try {
      return await userAgent.post(`/api/0.1/project-donation`, credentials);
    } catch (err) {
      return err.response;
    }
  };

  paymentWallisConfirmation = async ({ payload, donationId }) => {
    const { agent } = this;

    try {
      return await agent.patch(`/api/0.1/project-donation/${donationId}`, payload);
    } catch (err) {
      return err.response;
    }
  };

  paymentComment = async (credentials) => {
    const { agent } = this;

    try {
      return await agent.post(`/api/0.1${API_ROUTES.payment}/comment`, credentials);
    } catch (err) {
      return err.response;
    }
  };
}

const Service = new DonationService();

export default DonationService;
export { Service as DonationService };
