import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import { selectLoading } from 'redux/donation/selectors';
import { donationActions } from 'redux/donation/actions';

import ProjectHeader from '../../project-details/project-header';
import Stats from '../../project-details/stats';
import Loader from 'components/loaders/local-loader'

import { useQueryParam } from 'utils/helpers';

import * as S from './styles';

interface DonationParams {
  alias: string;
}

type Props = {};

const ProjectDonationSuccess = (props: Props) => {
  const dispatch = useDispatch();
  const { alias } = useParams<DonationParams>();
  const donationId = useQueryParam().get('id');
  const transactionId = useQueryParam().get('transaction_id');
  const state = useQueryParam().get('state');
  const { status } = useSelector(selectLoading);

  useMount(() => {
    const payload = {
      state: state === 'success' ? 1 : 0,
      transaction_id: transactionId
    };
    dispatch(donationActions.paymentWallisConfirmation({ donationId, payload, alias }));
  });

  return (
    <>
      <ProjectHeader />
      <Stats />

      <S.StyledContainer fluid>
        <S.DefaultContainer fluid='lg'>
          { status && <Loader /> }
        </S.DefaultContainer>
      </S.StyledContainer>
    </>
  );
};

export default ProjectDonationSuccess;
