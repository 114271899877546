import { useState, useEffect } from 'react';
import { Col, Form, Row, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser, selectIsLoggedIn } from 'redux/auth/selectors';
import { selectAmount, selectAnonymous, selectPaymentAlertState } from 'redux/donation/selectors';
import { donationActions } from 'redux/donation/actions';
import { selectProject } from 'redux/project/selectors';
import { FREE_MAX_PAYMENT, MAX_PAYMENT, PAYDIRECT_MAX_PAYMENT } from 'constants/payment-statuses';
import { getServerByHost } from 'config';
import { isSpecialProject } from 'utils/helpers';

import ProjectHeader from '../../project-details/project-header';
import Stats from '../../project-details/stats';
import { Donation } from '../stats/donation';
import CollapseCheckboxForm from '../content/collapse/checkbox_form';
import Tables from './tables';
import TermsLabel from 'components/terms-label';

import * as S from './styles';

type Props = {};

const PageDonation = (props: Props) => {
  const { alias } = useSelector(selectProject);
  const user = useSelector(selectUser);
  const amount = useSelector(selectAmount);
  const anonymous = useSelector(selectAnonymous);
  const { isVisible } = useSelector(selectPaymentAlertState);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  const [termsStatus, setTermsStatus] = useState<number>(0);
  const [paymentType, setPaymentType] = useState('bank');
  const [openForm, setOpen] = useState<boolean>(false);
  const [receiptStatus, setReceiptStatus] = useState<number>(0);
  const [receiptData, setReceiptData] = useState({});

  useEffect(() => {
    const initValues = {
      donationReceiptFirstname: user?.firstname,
      donationReceiptLastname: user?.lastname,
      donationReceiptCountry: user?.country,
      donationReceiptCity: user?.city,
      donationReceiptStreet: user?.street,
      donationReceiptHouse: '',
      donationReceiptZip: user?.zip,
      donationReceiptAdditionalAddressInformation: '',
      donationReceiptEmail: user?.email
    };

    setReceiptData(receiptStatus ? initValues : {});
  }, [user, receiptStatus]);


  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    switch (paymentType) {
      case 'bank': {
        const sepaPayload = {
          amount,
          project_alias: alias,
          payment_strategy: 'sepa',
          'anonymous-support': isLoggedIn ? anonymous : '1',
          donation_receipt: receiptData
        };
        dispatch(donationActions.paymentSepa(sepaPayload));
        break;
      }
      default: {
        const wallisPayload = {
          amount,
          project_alias: alias,
          success_url: `${getServerByHost(window.location.hostname).url}/project/${alias}/donation/success`,
          payment_strategy: 'plain',
          'anonymous-support': isLoggedIn ? anonymous : '1',
          donation_receipt: receiptData
        };
        dispatch(donationActions.paymentWallis(wallisPayload));
      }
    }
  };

  const handleChangeReceipt = (event) => {
    const value = event.target.checked;

    setOpen(!openForm)
    setReceiptStatus(value ? 1 : 0)
  };

  const handleChangeAmount = (event) => {
    const parsedValue = parseInt(event.target.value, 10);
    const valueToUpdate = parsedValue || '0';

    event.target.setCustomValidity('');

    if ((parsedValue || 0) < 1) {
      event.target.setCustomValidity('Der Mindestbetrag einer Spende liegt bei einem Euro.')
    }

    dispatch(donationActions.setAmount(valueToUpdate))
  }

  const handleChangeAnonymous = (event) => {
    const value = event.target.checked;

    dispatch(donationActions.setAnonymous(value ? '1' : '0' ))
  }

  const handleCloseAlert = () => dispatch(donationActions.setPaymentAlert({ isVisible: false }));

  const handleValidate = (event) => {
    const value = parseInt(event.target.value, 10) || 0;

    if (paymentType === 'paydirekt' && value > PAYDIRECT_MAX_PAYMENT) {
      event.target.setCustomValidity('Spenden über paydirekt ist nur bis 1.000 € möglich. Bitte nutzen Sie für höhere Beträge eine andere Zahlungsmethode.');
    } else if (!isLoggedIn && value > FREE_MAX_PAYMENT) {
      event.target.setCustomValidity(`Anonymes Spenden ist nur bei Beträgen unter ${FREE_MAX_PAYMENT + 1} € möglich. Bitte melden Sie sich für höhere Spendenbeträge an (zur Anmeldung/Registrierung s. Menüleiste oben rechts).`);
    } else if (isLoggedIn && value > MAX_PAYMENT) {
      event.target.setCustomValidity(`Der maximale Spendenbetrag liegt bei ${MAX_PAYMENT} Euro.`);
    } else {
      event.target.setCustomValidity('Der Mindestbetrag einer Spende liegt bei einem Euro.');
    }
  };

  const renderAnonymousBlock = () => (
    <>
      <S.FormCheck
        type='checkbox'
        label='Ich möchte anonym spenden'
        name='anonymous'
        checked={anonymous === '1'}
        onChange={handleChangeAnonymous}
      />

      <S.CheckDescription>
        Wenn Sie für ein Projekt anonym spenden, erscheint die Spende auf der Plattform ohne Angabe Ihres Benutzernamens.
      </S.CheckDescription>
    </>
  )

  const setMaxPayload = () => {
    const amountInput:any = document.getElementById('amount-field');
    amountInput && amountInput.setCustomValidity('');

    if (paymentType === 'paydirekt') return PAYDIRECT_MAX_PAYMENT;

    return isLoggedIn ? MAX_PAYMENT : FREE_MAX_PAYMENT;
  };

  return (
    <>
      <ProjectHeader />
      <Stats />
      <S.StyledContainer fluid>
        <S.DefaultContainer fluid='lg'>
        <Alert variant='danger' show={isVisible} onClose={handleCloseAlert} dismissible >
          Etwas ist schief gelaufen
        </Alert>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row>
                <Col md={{span: 7}}>
                  <S.InfoBlock>{Donation.euro_label}</S.InfoBlock>
                  <Row>
                    <Col xs={10} md={8}>
                      <Form.Control
                        id='amount-field'
                        type='number'
                        min={1}
                        max={setMaxPayload()}
                        step={1}
                        value={amount.toString()}
                        onChange={handleChangeAmount}
                        onInvalid={handleValidate}
                        name='amount'
                      />
                    </Col>
                    <S.CurrencyCol xs={2}><span className='amount'>EUR</span></S.CurrencyCol>
                  </Row>
                </Col>

                <Col md={{span: 5}}>
                  <Row>
                    <Col xs={2} md={3}><S.Image src='/ssl.png'/></Col>
                    <S.SslCol xs={10} md={9}>
                      <Row><S.SSLText>{Donation.ssl_label}</S.SSLText></Row>
                      <Row>{Donation.ssl_text}</Row>
                    </S.SslCol>
                  </Row>
                </Col>
              </Row>
              { isLoggedIn && renderAnonymousBlock() }

              <S.FormCheck
                type='checkbox'
                label={`Ich möchte eine Spendenbescheinigung${isSpecialProject(alias) ? ' (ab 300 Euro möglich)' : ''}`}
                checked={receiptStatus === 1}
                onChange={handleChangeReceipt}
              />

              { openForm && <CollapseCheckboxForm receiptData={receiptData} setReceiptData={setReceiptData}/> }

              <S.FormCheck
                type='checkbox'
                required
                label={<TermsLabel />}
                checked={termsStatus === 1}
                onChange={(e) => setTermsStatus(e.target.checked ? 1 : 0)}
              />
              <S.TableLabel>{Donation.tables_title}</S.TableLabel>
              <Tables paymentType={paymentType} setPaymentType={setPaymentType}/>
            </Form.Group>
          </Form>
        </S.DefaultContainer>
      </S.StyledContainer>
    </>
  );
};

export default PageDonation;

