import { makeActionCreator } from 'utils/ReduxUtils';

const prefix = '[Donation]';

const TYPES = {
	AMOUNT_SET: `${prefix} amount_set`,
	ANONYMOUS_SET: `${prefix} anonymous_set`,
	PAYMENT_SEPA: `${prefix} paymentSepa`,
	PAYMENT_WALLIS: `${prefix} paymentWallis`,
	PAYMENT_WALLIS_CONFIRMATION: `${prefix} paymentWallisConfirmation`,
	PAYMENT_COMMENT: `${prefix} paymentComment`,
	PAYMENT_RESPONSE_SET: `${prefix} payment_response_set`,
	LOADING_SET: `${prefix} loading_set`,
	PAYMENT_ALERT_SET: `${prefix} payment_alert_set`,
};

const actions = {
	...TYPES,
	setAmount: makeActionCreator(TYPES.AMOUNT_SET),
	setAnonymous: makeActionCreator(TYPES.ANONYMOUS_SET),
	paymentSepa: makeActionCreator(TYPES.PAYMENT_SEPA),
	paymentWallis: makeActionCreator(TYPES.PAYMENT_WALLIS),
	paymentWallisConfirmation: makeActionCreator(TYPES.PAYMENT_WALLIS_CONFIRMATION),
	paymentComment: makeActionCreator(TYPES.PAYMENT_COMMENT),
	setPaymentResponse: makeActionCreator(TYPES.PAYMENT_RESPONSE_SET),
	setLoading: makeActionCreator(TYPES.LOADING_SET),
	setPaymentAlert: makeActionCreator(TYPES.PAYMENT_ALERT_SET),
};

export default actions;
export { actions as donationActions };
