import { Col, Container, FormGroup, Row } from 'react-bootstrap';

import { Donation } from '../stats/donation';

import CollapseBankCheck from '../content/collapse/bank_check';
import CollapseWallis from '../content/collapse/wallis';

import * as S from './styles';

interface PaymentProps {
  paymentType: string;
  setPaymentType: (value: string) => void;
};

const Tables = ({ paymentType, setPaymentType }: PaymentProps) => {
  const renderBankTable = () => (
    <div id={'second-table'}>
      <S.Image src='/bank.png' />
      <div key={`default-radio`} className='mb-3'>
        <S.FormCheck
          type={'radio'}
          id={`bank-radio-1`}
          name='bank'
          checked={paymentType === 'bank'}
          onChange={() => setPaymentType('bank')}
          label={Donation.table_radios[0].label}
        />
      </div>
      <S.Description>{Donation.table_radios[0].paragraphs[0].text}</S.Description>
      { paymentType === 'bank' && <CollapseBankCheck /> }
    </div>
  );

  const renderWallisTable = () => (
    <div id={'first-table'}>
      <S.Image src='/wallis.png' />
      <div key={`default-radio`} className='mb-3'>
        <S.FormCheck
          type={'radio'}
          id={`wallis-radio-1`}
          name='wallis'
          checked={paymentType === 'wallis'}
          onChange={() => setPaymentType('wallis')}
          label={Donation.table_radios[1].label}
        />
      </div>
      <S.Description>{Donation.table_radios[1].paragraphs[0].text}</S.Description>
      { paymentType === 'wallis' && <CollapseWallis /> }
    </div>
  );

  return (
    <Container fluid>
      <Row>
        <FormGroup>
          <Row>
            <Col xs={12} sm={6}>{renderWallisTable()}</Col>
            <Col xs={12} sm={6}>{renderBankTable()}</Col>
          </Row>
        </FormGroup>
      </Row>
    </Container>
  )
};

export default Tables;
